import React, { useState } from "react";
import TopNav from "./TopNav";
import CarDashboard from "./reusable/CarDashboard";
import ApiProxy from "../api/ApiProxy";
import { Layout } from "antd";
const { Header, Content } = Layout;
const App = () => {
  const getCarDashboardUrl = () => {
    if (window.location.hash.length < 1) return "";
    return window.location.hash.substr(1);
  };
  const changeDealer = () => {
    setRefreshCarDashboard(!refreshCarDashboard);
  };
  const [refreshCarDashboard, setRefreshCarDashboard] = useState(false);
  const [carDashboardUrl, setCarDashboardUrl] = useState(getCarDashboardUrl);
  const [navigationLinks, setNavigationLinks] = useState([
    {
      label: "Home",
      items: [
        {
          items: [],
          label: "CarDashboard",
          url:
            "/CarDashboard/ploader.aspx?TargetControl=AdminControls/DealerSP.ascx&SelectedTab=t_Admin"
        }
      ]
    }
  ]);
  const runJavascriptFunctionOnCarDashboardFrame = functionString => {
    // TODO: This is really just used for topnav recent customers. Move this elsewhere
    /* eslint-disable-next-line no-script-url */
    const functionName = functionString.replace("javascript:top.", "");
    const regExp = /\(([^)]+)\)/;
    const matches = regExp.exec(functionName);
    let variable = "";
    if (matches) variable = matches[1];
    const childWindow = window.getChildWindow();
    if (!childWindow) {
      /* eslint-disable-next-line no-console */
      console.error("No child window when looking for " + functionString);
      return false;
    }
    const functionRefrence = childWindow[functionName.split("(")[0]];
    if (typeof functionRefrence === "function") {
      return functionRefrence(variable);
    }
  };
  const _setCarDashboardUrl = url => {
    /* eslint-disable-next-line no-script-url */
    if (url.startsWith("javascript:")) {
      runJavascriptFunctionOnCarDashboardFrame(url);
    } else {
      window.location.hash = url;
      setCarDashboardUrl(url);
    }
  };
  return (
    <div id="CarDashboard-CX">
      <Layout className="layout">
        <Header id="CxComponentTopNav_rims">
          <TopNav
            runCarDashboardScript={runJavascriptFunctionOnCarDashboardFrame}
            navigationLinks={navigationLinks}
            setCarDashboardUrl={_setCarDashboardUrl}
            changeDealer={changeDealer}
          />
        </Header>
        <Content>
          {carDashboardUrl && (
            <>
              <ApiProxy />
              <CarDashboard
                setNavigationLinks={setNavigationLinks}
                url={carDashboardUrl}
                refresh={refreshCarDashboard}
              />
            </>
          )}
        </Content>
      </Layout>
    </div>
  );
};

export default App;
