import configFiles from "./config.json";
export const getEnviromentName = url => {
  const domainRegex = /(?<=\/crm\/)[a-z]*/g;
  return domainRegex.test(url) ? url.match(domainRegex) : "local";
};

export const getConfig = url => {
  const enviromentName = getEnviromentName(url);
  return configFiles[enviromentName];
};

export const getConfigFromEnviroment = enviromentName => {
  return configFiles[enviromentName];
};

export const getCarDashboardUrl = url => {
  const enviromentName = getEnviromentName(url);
  const config = configFiles[enviromentName];
  return config.carDashboardUrl;
};
