import React from "react";
import { getCarDashboardUrl } from "../helpers";
const ApiProxy = () => {
  return (
    <iframe
      style={{ display: "none" }}
      width="0"
      height="0"
      id="apiProxy"
      title="apiProxy"
      src={
        getCarDashboardUrl(window.location.pathname) +
        "/CarDashboard/pages/blankpage.aspx"
      }
    />
  );
};

export default ApiProxy;
