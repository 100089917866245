import React from "react";
import { render } from "react-dom";
import App from "./components";
import "./index.scss";

(function() {
  const root = document.getElementById("root");
  if (!root) return;
  render(<App />, root);
})();
