import React from "react";

import IconSearch from "@cx/ui/Icons/IconSearch";
import Button from "@cx/ui/Button";
import { Menu, Tabs, Input } from "antd";
const { TabPane } = Tabs;
const { SubMenu } = Menu;
const Search = props => {
  return (
    <SubMenu {...props} title={<IconSearch />}>
      <div className="tabsinnav" id="SearchMenu">
        <Tabs>
          <TabPane tab="Customer" key="1">
            <Input.Search
              placeholder="input search text"
              onSearch={value =>
                (window.location =
                  "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&rightpaneframe=HIDE&leftpaneframe=QuickSearch.aspx?searchdata=" +
                  encodeURI(value))
              }
              enterButton
            />
          </TabPane>
          <TabPane tab="Inventory" key="2">
            <Input.Search
              placeholder="input search text"
              onSearch={value =>
                (window.location =
                  "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&rightpaneframe=VehicleList.aspx?searchdata=" +
                  encodeURI(value))
              }
              enterButton
            />
          </TabPane>
        </Tabs>
        <Button
          className="nav-dropdown-button-bottom"
          onClick={() => {
            window.location =
              "/CarDashboard/Pages/LeadManagement/ActiveLeadsLayout.aspx?SelectedTab=t_ILM&leftpaneframe=AdvancedSearch2.aspx&rightpaneframe=HIDE";
          }}
          // htmlId={"signout-button"}
          buttonStyle="link"
        >
          Advanced Search
        </Button>
      </div>
    </SubMenu>
  );
};

export default Search;
