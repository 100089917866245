import React, { useState } from "react";
import PropTypes from "prop-types";
import { List } from "antd";
import Button from "@cx/ui/Button";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import DealerSelector from "./DealerSelector";
import ModalDialog from "@cx/ui/ModalDialog";

const Account = ({
  runCarDashboardScript,
  setCarDashboardUrl,
  changeDealer
}) => {
  const [showDealerSelect, setShowDealerSelect] = useState(false);
  const [dealerName, setDealerNMame] = useState(
    runCarDashboardScript("getDealerName()")
  );
  const { oidcUser } = useReactOidc();
  const { profile } = oidcUser;
  const dealerChanged = newDealer => {
    setShowDealerSelect(false);
    setDealerNMame(newDealer.DealerName);
    changeDealer();
  };
  const data = [
    {
      id: "newsLink",
      text: "News",
      onClick: () => {
        setCarDashboardUrl("/CarDashboard/Pages/News/News.aspx");
      }
    },
    {
      id: "trainingLink",
      text: "Training",
      onClick: () => {
        window.open(
          "http://www.vinsolutions.com/Customer-Support/eacademy",
          "_blank"
        );
      }
    },
    {
      id: "supportLink",
      text: "Support",
      onClick: () => {
        setCarDashboardUrl("/CarDashboard/Services/SalesForceCommunities.ashx");
      }
    },
    {
      id: "feedbackLink",
      text: "Feedback",
      onClick: () => {
        runCarDashboardScript('OpenFeedbackWindow("CarDashboard")');
      }
    }
  ];
  if (typeof window.WalkMePlayerAPI !== "undefined") {
    data.push({
      id: "walkMeLink",
      text: "WalkMe",
      onClick: () => {
        window.location = window.WalkMePlayerAPI.toggleMenu;
      }
    });
  }
  return (
    <>
      <ModalDialog
        htmlId="DealerSelectorModal"
        show={showDealerSelect}
        header={<ModalDialog.Title>Dealer Selector</ModalDialog.Title>}
        onHide={() => setShowDealerSelect(false)}
      >
        <DealerSelector setDealer={dealerChanged} />
      </ModalDialog>
      <Button
        // onClick={() => runCarDashboardScript("ShowSystemDealerSelector()")}
        onClick={() => setShowDealerSelect(!showDealerSelect)}
        htmlId="dealerSelector"
        buttonStyle="link"
      >
        {dealerName}
      </Button>
      <Button
        onClick={() => {
          setCarDashboardUrl(
            "/CarDashboard/Pages/dynamicframe.aspx?SelectedTab=t_Settings&leftpaneframe=DealerUserList.aspx&rightpaneframe=HIDE"
          );
        }}
        htmlId="userSelector"
        buttonStyle="link"
      >
        {profile.given_name} {profile.family_name}
      </Button>
      <List
        size="small"
        dataSource={data}
        split={false}
        renderItem={item => (
          <List.Item id={item.id + "-li"}>
            <Button onClick={item.onClick} htmlId={item.id} buttonStyle="link">
              {item.text}
            </Button>
          </List.Item>
        )}
      />
      {/* TODO: Run own signout code */}
      <Button
        className="nav-dropdown-button-bottom"
        onClick={() => {
          setCarDashboardUrl("/CarDashboard/logout.aspx");
        }}
        buttonStyle="link"
      >
        Sign Out
      </Button>
    </>
  );
};

Account.propTypes = {
  changeDealer: PropTypes.func.isRequired,
  runCarDashboardScript: PropTypes.func.isRequired,
  setCarDashboardUrl: PropTypes.func.isRequired
  // viewingAs: PropTypes.func.isRequired
};

export default Account;
