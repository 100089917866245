import React from "react";
import PropTypes from "prop-types";
import { Menu } from "antd";
const { SubMenu } = Menu;

const NavigationSubMenus = ({ tabs, onClick }) => {
  const getSubMenuItems = () => {
    return tabs.map(tab => {
      return (
        <SubMenu key={tab.label} title={tab.label}>
          {tab.items.map(menuItem => {
            if (menuItem.items.length > 0) {
              return (
                <SubMenu
                  key={tab.label + menuItem.label}
                  title={menuItem.label}
                >
                  {menuItem.items.map(subMenuItem => {
                    if (subMenuItem.items.length > 0) {
                      return (
                        <SubMenu
                          key={tab.label + menuItem.label + subMenuItem.label}
                          title={subMenuItem.label}
                        >
                          {subMenuItem.items.map(subSubMenuItem => {
                            return (
                              <Menu.Item
                                onClick={onClick}
                                dataurl={subSubMenuItem.url}
                                key={
                                  tab.label +
                                  menuItem.label +
                                  subMenuItem.label +
                                  subSubMenuItem.label +
                                  subSubMenuItem.url
                                }
                              >
                                {subSubMenuItem.label}
                              </Menu.Item>
                            );
                          })}
                        </SubMenu>
                      );
                    } else {
                      return (
                        <Menu.Item
                          key={
                            tab.label +
                            menuItem.label +
                            subMenuItem.label +
                            subMenuItem.url
                          }
                          onClick={onClick}
                          dataurl={subMenuItem.url}
                        >
                          {subMenuItem.label}
                        </Menu.Item>
                      );
                    }
                  })}
                </SubMenu>
              );
            } else {
              return (
                <Menu.Item
                  key={tab.label + menuItem.label + menuItem.url}
                  onClick={onClick}
                  dataurl={menuItem.url}
                >
                  {menuItem.label}
                </Menu.Item>
              );
            }
          })}
        </SubMenu>
      );
    });
  };
  return (
    <Menu mode="horizontal" triggerSubMenuAction={"click"} className="clear">
      {getSubMenuItems()}
    </Menu>
  );
};
NavigationSubMenus.propTypes = {
  onClick: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired
};

export default NavigationSubMenus;
