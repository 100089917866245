import React, { useState } from "react";
import TextInput from "@cx/ui/TextInput";
import PropTypes from "prop-types";
import ToggleSwitch from "@cx/ui/ToggleSwitch";
import {
  searchDealers,
  setSelectedDealer
} from "../../../../../api/carDashboard";
import { Button, Table } from "antd";

const DealerSelector = ({ setDealer }) => {
  const [form, setForm] = useState({ searchText: "", activeDealersOnly: true });
  const [dealers, setDealers] = useState([]);
  const formChange = async e => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const searchDealerClicked = async () => {
    setDealers(await searchDealers(form));
  };
  const selectDealerClicked = async record => {
    await setSelectedDealer(record.DealerID);
    setDealer(record);
  };

  const columns = [
    {
      title: "",
      key: "select",
      dataIndex: "DealerID",
      /* eslint-disable react/no-multi-comp */
      /* eslint-disable react/display-name */
      render: (_dealerId, record) => (
        <Button onClick={() => selectDealerClicked(record)}>Select</Button>
      )
      /* eslint-enable react/no-multi-comp */
      /* eslint-enable react/display-name */
    },
    {
      title: "Dealer ID",
      dataIndex: "DealerID",
      key: "DealerID"
    },
    {
      title: "Name",
      dataIndex: "DealerName",
      key: "DealerName"
    },
    {
      title: "City",
      dataIndex: "City",
      key: "City"
    },
    {
      title: "State",
      dataIndex: "State",
      key: "State"
    }
  ];
  return (
    <div id="dealerSelectorContainer">
      <TextInput
        addOnPosition="append"
        htmlId="textInputPrepend"
        label="Text with prepended addon button"
        name="searchText"
        onChange={formChange}
        value={form.searchText}
      >
        <TextInput.Addon
          addonType="button"
          buttonStyle="primary"
          htmlId="textInputPrependButton"
          onClick={searchDealerClicked}
        >
          Search
        </TextInput.Addon>
      </TextInput>
      <ToggleSwitch
        htmlId="ToggleSwitchWithLabel1"
        label="Active Dealers Only"
        name="activeDealersOnly"
        defaultChecked={form.activeDealersOnly}
        onClick={formChange}
      />
      {dealers && dealers.length > 0 && (
        <Table
          rowKey={record => record.DealerID}
          dataSource={dealers}
          columns={columns}
        />
      )}
    </div>
  );
};

DealerSelector.propTypes = {
  setDealer: PropTypes.func.isRequired
};

export default DealerSelector;
