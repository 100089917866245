import IFrameMessageHelper from "../IFrameMessageHelper";

// Topic strings need to match /public/CarDashboardIframe.js
export const topics = {
  openCustomerDashboard: "openCustomerDashboard",
  getSelectedDealerID: "getSelectedDealerID",
  recieveIdsJwt: "recieveIdsJwt",
  runFunction: "runFunction",
  getData: "getData",
  lifeCycle: "lifeCycle"
};
class _CarDashboardMethods {
  _iFrameMessageHelper = null;
  constructor(iframeRef, loadingStateChanged) {
    this._iFrameMessageHelper = new IFrameMessageHelper(
      iframeRef,
      loadingStateChanged
    );
  }
  sendMessage = message => {
    return this._iFrameMessageHelper.sendMessage(message);
  };

  setRef = iframeRef => {
    this._iFrameMessageHelper.setRef(iframeRef);
  };

  recieveReady =
    this._iFrameMessageHelper && this._iFrameMessageHelper._iframeRef !== null;
  sendReady = this._iFrameMessageHelper && this._iFrameMessageHelper.isReady;

  runFunction = async (functionName, functionProperties) => {
    return await this.sendMessage({
      topic: topics.runFunction,
      data: { functionName, functionProperties },
      timeout: 4000
    });
  };

  getData = async variableName => {
    return await this.sendMessage({
      topic: topics.getData,
      data: variableName,
      timeout: 4000
    });
  };

  openCustomerDashboard = async globalCustomerId => {
    return await this.sendMessage({
      topic: topics.openCustomerDashboard,
      data: globalCustomerId,
      timeout: 4000
    });
  };

  getSelectedDealerID = async () => {
    return await this.sendMessage({
      topic: topics.getSelectedDealerID,
      timeout: 4000
    });
  };
  recieveIdsJwt = async () => {
    return await this.sendMessage({
      topic: topics.recieveIdsJwt,
      timeout: 4000
    });
  };
}
export default _CarDashboardMethods;
