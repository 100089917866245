const { getProxyApi } = require("./utils/proxyApiWrapper");
const cardashboardConfig = {
  baseUrls: {
    local: "http://localhost/",
    dev: "https://swbmotodv-mts01.dev.vinstickers.com/",
    qa: "https://moto.qa.vinsolutions.com/",
    staging: "https://staging-apps.vinmanager.com/",
    prod: "http://apps.vinmanager.com/"
  },
  headers: {
    timeout: 150000,
    headers: {
      Accept: `application/json; charset=UTF-8`,
      "content-type": "application/json; charset=UTF-8"
    }
  }
};

export async function setSelectedDealer(dealerId) {
  try {
    const response = await getProxyApi(
      `CarDashboard/services/topnav.asmx/SetDealer?dealerID=${dealerId}`,
      cardashboardConfig
    );
    return JSON.parse(response);
  } catch (error) {
    /* eslint-disable-next-line no-console */
    console.log(error);
    return false;
  }
}

export async function searchDealers(params) {
  try {
    const response = await getProxyApi(
      `CarDashboard/services/topnav.asmx/GetDealers?searchText=${params.searchText}&activeDealersOnly=${params.activeDealersOnly}`,
      cardashboardConfig
    );
    return JSON.parse(response);
  } catch (error) {
    /* eslint-disable-next-line no-console */
    console.log(error);
    return false;
  }
}
