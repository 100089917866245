class _IFrameMessageHelper {
  _iframeRef = null;
  _loadingStateChanged = null;
  constructor(iframeRef, loadingStateChanged) {
    if (iframeRef) this._iframeRef = iframeRef;
    if (!this.listenerAttached) this.attachListener();
    if (loadingStateChanged) this._loadingStateChanged = loadingStateChanged;
  }
  messages = [];
  /**
   * @param {object} message - The type of message to be sent, such as 'revievejwt' or 'openpage'.
   * @param {string} message.topic - The type of message to be sent, such as 'revievejwt' or 'openpage'.
   * @param {object} [message.data] - Object to send to CarDashboard.
   * @param {number} [message.timeout] - How long to wait for a response in ms.
   * @returns {Promise.<any, Error>} A promise that returns a something if resolved,
   *     or an Error if rejected.
   */
  setRef = iframeRef => {
    this._iframeRef = iframeRef;
    if (!this._loadingStateChanged) return;
    iframeRef.onload = () => {
      this._loadingStateChanged(false);
    };
  };
  handleLifecycleChange = data => {
    if (!this._loadingStateChanged) return;
    const { method } = JSON.parse(data);
    if (method === "beforeunload") this._loadingStateChanged(true);
    if (method === "DOMContentLoaded") this._loadingStateChanged(false);
  };
  listener = event => {
    // console.log("IFMH Message Recieved", event.data);
    if (!this.isEventValid(event)) return;
    const messageId = event.data.messageId;
    if (messageId === "lifeCycle") {
      this.handleLifecycleChange(event.data.message);
      return;
    }
    const message = this.findMessage(messageId);
    if (!message) return;
    message.resolve(event.data.message);
    this.removeMessage(messageId);
  };
  sendMessage = ({ topic, data, timeout }) => {
    return new Promise((resolve, reject) => {
      const messageId = this.addMessage(resolve, reject);
      const sender = "IFrameMessageHelper";
      // console.log("IFMH Message Sent", { messageId, topic, data, sender });
      this._iframeRef.contentWindow.postMessage(
        {
          messageId,
          topic,
          data,
          sender
        },
        "*"
      ); // TODO: RESTRICT DOMAIN
      if (timeout) this.handleTimeout(reject, messageId, timeout);
    });
  };
  handleTimeout = (reject, messageId, timeout) => {
    setTimeout(() => {
      if (this.findMessage(messageId)) return;
      reject(`Timeout after ${timeout}ms`);
      this.removeMessage(messageId);
    }, timeout);
  };
  addMessage = (resolve, reject) => {
    const messageId = this.generateId();
    this.messages.push({
      messageId,
      resolve,
      reject
    });
    return messageId;
  };
  removeMessage = messageId => {
    this.messages.splice(this.findMessage(messageId), 1);
  };
  findMessage = messageId => {
    return this.messages[
      this.messages.findIndex(message => message.messageId === messageId)
    ];
  };
  isEventValid = event =>
    event &&
    event.data &&
    event.data.messageId &&
    event.data.sender === "CarDashboardIframe";
  listenerAttached = false;
  attachListener = () => {
    this.listenerAttached = true;
    window.addEventListener("message", this.listener);
  };
  generateId = () => {
    return (
      "_" +
      Math.random()
        .toString(36)
        .substr(2, 9)
    );
  };
}

export default _IFrameMessageHelper;
