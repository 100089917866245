import React from "react";
import { Switch, Route } from "react-router-dom";
import { withOidcSecure } from "@axa-fr/react-oidc-context";
import Connect from "./connect";

const PageNotFound = error => (
  <div>Page not found {JSON.stringify(error)}}</div>
);

/* eslint-disable react/no-multi-comp */
const Routes = () => (
  <Switch>
    <Route exact path="/" component={withOidcSecure(Connect)} />
    <Route path="/crm/dev" component={withOidcSecure(Connect)} />
    <Route path="/crm/qa" component={withOidcSecure(Connect)} />
    <Route path="/crm/staging" component={withOidcSecure(Connect)} />
    <Route path="/crm/prod" component={withOidcSecure(Connect)} />
    <Route component={PageNotFound} />
  </Switch>
);
/* eslint-enable react/no-multi-comp */

export default Routes;
