import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import Auth from "./auth";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Auth>
          <Routes />
        </Auth>
      </BrowserRouter>
    </div>
  );
};

export default App;
