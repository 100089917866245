// All global axios api configurations go here, they can be overwritten
// if needed on a case by case basis in each individual api.
module.exports = {
  responseType: "json",
  withCredentials: true,
  timeout: 150000,
  headers: {
    "X-CoxAuto-ReturnNulls": "true",
    Accept: `application/vnd.coxauto.v1+json`,
    "content-type": `application/vnd.coxauto.v1+json`
  }
};
