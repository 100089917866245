const apiConfig = require("./apiConfig");
const { env } = require("./environment");

function withSetBaseUrl(config) {
  const { baseUrls, ...other } = config;
  const newConfig = { ...other };
  if (baseUrls && baseUrls[env]) {
    newConfig.baseURL = baseUrls[env];
  }
  return newConfig;
}

function mergeConfig(config1, config2, jwt) {
  const config = withSetBaseUrl(config1);
  const custom = withSetBaseUrl(config2);
  const mergedConfig = Object.assign({}, config, custom);

  if (!mergedConfig.baseURL) {
    mergedConfig.baseURL = "/";
  }

  return mergedConfig;
}

// TODO Use a REF to the iFrame instead of getting the element each time
function proxyApiWrapper(config) {
  // Return public functions
  // The config param is optional on all.
  return {
    get(url, customConfig) {
      const mergedConfig = mergeConfig(config, customConfig);
      const encudedUrl = encodeURI(url);
      return window.document
        .getElementById("apiProxy")
        .contentWindow.$.get(mergedConfig.baseURL + encudedUrl);
    }
  };
}

const { get } = proxyApiWrapper(apiConfig);

export const getProxyApi = (paramString, config) => get(paramString, config);
