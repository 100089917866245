// Main repo: https://github.com/IdentityModel/oidc-client-js/wiki
// NPM used: https://github.com/AxaGuilDEv/react-oidc/tree/master/packages/context#readme.md
// Example: https://github.com/AxaGuilDEv/react-oidc/blob/master/examples/context/src/configuration.js

import React from "react";
import PropTypes from "prop-types";
// https://github.com/AxaGuilDEv/react-oidc/tree/master/packages/context#readme.md
import { AuthenticationProvider } from "@axa-fr/react-oidc-context";
import {
  Authenticating,
  CallbackComponentOverride,
  SessionLostComponent,
  NotAuthorized,
  NotAuthenticated
} from "./pages";
import oidcConfiguration from "./oidcConfiguration";

const Auth = props => {
  return (
    <AuthenticationProvider
      notAuthenticated={NotAuthenticated}
      notAuthorized={NotAuthorized}
      authenticating={Authenticating}
      callbackComponentOverride={CallbackComponentOverride}
      sessionLostComponent={SessionLostComponent}
      configuration={oidcConfiguration}
    >
      {props.children}
    </AuthenticationProvider>
  );
};

Auth.propTypes = {
  children: PropTypes.element
};
export default Auth;
