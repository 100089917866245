// import React, { useState } from "react";
import React from "react";
import PropTypes from "prop-types";
// import IconMenu from "@cx/ui/Icons/IconMenu";
// import IconFlag from "@cx/ui/Icons/IconFlag";
import { Menu } from "antd";
import SearchSubMenu from "./SearchSubMenu";
import NavigationSubMenus from "./NavigationSubMenus";
import AccountSubMenu from "./AccountSubMenu";
// const { SubMenu } = Menu;

const TopNav = ({
  navigationLinks,
  setCarDashboardUrl,
  runCarDashboardScript,
  changeDealer
}) => {
  // const [navManu, setNavMenu] = useState([]);
  // const [viewingAs, setViewingAs] = useState(null);
  // const [userName, setUserName] = useState(null);
  // const [dealerName, setDealerName] = useState(null);
  // const [kpiData, setKpiData] = useState({});
  // window.cxComms = {
  //   documentReady: () => {
  //     // setViewingAs(window.getViewingAs());
  //     // setUserName(window.getUserName());
  //     // setDealerName(window.getDealerName());
  //     // setKpiData(window.getKpis());
  //   },
  //   pageLoad: () => {
  //     // TODO: Stop using CXCOMMS
  //     setNavMenu(window.getNavItems());
  //   }
  // };
  const setUrl = e => {
    const clickedUrl = e.item.props.dataurl;
    setCarDashboardUrl(clickedUrl);
  };
  return (
    <>
      <div className="connectLogo" />
      <Menu
        mode="horizontal"
        triggerSubMenuAction={"click"}
        className="pull-right"
      >
        <SearchSubMenu />
        <AccountSubMenu
          setCarDashboardUrl={setCarDashboardUrl}
          changeDealer={changeDealer}
          runCarDashboardScript={runCarDashboardScript}
          // kpiData={kpiData}
          // dealerName={dealerName}
          // viewingAs={viewingAs}
          // userName={userName}
        />
      </Menu>
      <NavigationSubMenus tabs={navigationLinks} onClick={setUrl} />
    </>
  );
};

TopNav.propTypes = {
  changeDealer: PropTypes.func.isRequired,
  navigationLinks: PropTypes.array.isRequired,
  runCarDashboardScript: PropTypes.func.isRequired,
  setCarDashboardUrl: PropTypes.func.isRequired
};

export default TopNav;
