import { getConfig } from "../../helpers";
const getOidcConfiguration = config => {
  /* eslint-disable camelcase */
  return {
    client_id: config.clientId,
    client_secret: config.clientSecret,
    redirect_uri: config.root + "/authentication/callback",
    response_type: "code",
    acr_values: config.acrValues,
    scope: "openid profile offline_access",
    authority: config.idsUrl,
    silent_redirect_uri: config.root + "/authentication/silent_callback",
    automaticSilentRenew: true,
    loadUserInfo: true,
    post_logout_redirect_uri: config.root + "/",
    monitorSession: false
  };
  /* eslint-enable camelcase */
};

const config = getConfig(window.location.pathname);
const oidcConfiguration = getOidcConfiguration(config);

export default oidcConfiguration;
