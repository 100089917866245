import React from "react";
import PropTypes from "prop-types";
import AccountAccount from "./Account/Account";
import IconPerson from "@cx/ui/Icons/IconPerson";
import { Menu, Tabs } from "antd";
const { TabPane } = Tabs;
const { SubMenu } = Menu;

const AccountSubMenu = props => {
  const {
    changeDealer,
    runCarDashboardScript,
    setCarDashboardUrl,
    ...other
  } = props;
  return (
    <SubMenu title={<IconPerson />} {...other}>
      <div className="tabsinnav" id="PersonMenu">
        <Tabs animated={false} size="small">
          <TabPane tab="Dashboard" key="1" forceRender>
            {/* <Kpi kpiData={kpiData} /> */}
          </TabPane>
          <TabPane tab="Account" key="2" forceRender>
            <AccountAccount
              setCarDashboardUrl={setCarDashboardUrl}
              runCarDashboardScript={runCarDashboardScript}
              changeDealer={changeDealer}
              // dealerName={dealerName}
              // viewingAs={viewingAs}
              // userName={userName}
            />
          </TabPane>
        </Tabs>
      </div>
    </SubMenu>
  );
};

AccountSubMenu.propTypes = {
  changeDealer: PropTypes.func.isRequired,
  // dealerName: PropTypes.func.isRequired,
  // kpiData: PropTypes.object.isRequired,
  runCarDashboardScript: PropTypes.func.isRequired,
  setCarDashboardUrl: PropTypes.func.isRequired
  // userName: PropTypes.func.isRequired,
  // viewingAs: PropTypes.object.isRequired
};

export default AccountSubMenu;
