import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import CarDashboardMethods from "./CarDashboardMethods";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import { getEnviromentName, getConfigFromEnviroment } from "../../../helpers";

const CarDashboard = ({ url, setNavigationLinks, refresh }) => {
  const [contentRef, setContentRef] = useState(false);
  const [carDashboardMethods, setCarDashboardMethods] = useState(null);
  const [dataIsland, setDataIsland] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setContentRef(false);
    setCarDashboardMethods(null);
    setDataIsland(null);
    setLoading(true);
  }, [refresh]);
  useEffect(() => {
    if (!carDashboardMethods)
      setCarDashboardMethods(new CarDashboardMethods(null, setLoading));
  }, [carDashboardMethods]);
  const updateRef = ref => {
    if (!ref) return;
    ref.onload = () => {
      carDashboardMethods.setRef(ref);
      setContentRef(true);
    };
  };
  useEffect(() => {
    if (!carDashboardMethods || !contentRef) return;
    if (!dataIsland) {
      carDashboardMethods.getData("_dataIsland").then(data => {
        setDataIsland(data);
      });
    }
    carDashboardMethods.runFunction("getNavItems").then(data => {
      setNavigationLinks(JSON.parse(data));
    });
  }, [carDashboardMethods, contentRef, dataIsland, setNavigationLinks]);
  if (!carDashboardMethods) return <div />;
  const getCarDashboardUrl = url => {
    let end = "";
    const enviroment = getEnviromentName(window.location.pathname);
    if (enviroment === "local") {
      end = "&localoverride=true";
    }
    const domain = getConfigFromEnviroment(enviroment).carDashboardUrl;
    return domain + url + end;
  };
  return (
    <div id="cardashboardframeContainer">
      {loading && (
        <LoadingIndicator
          color={"white"}
          htmlId="CarDasboardLoading"
          size="large"
        />
      )}
      <iframe
        title="CarDashboard"
        className={`cardashboardframe_loading-${loading}`}
        ref={contentRef => updateRef(contentRef)}
        id="cardashboardframe"
        width="100%"
        src={getCarDashboardUrl(url)}
      />
    </div>
  );
};

CarDashboard.propTypes = {
  refresh: PropTypes.bool.isRequired,
  setNavigationLinks: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired
};

export default CarDashboard;
